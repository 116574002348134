<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <!-- <th role="columnheader" class="text-center">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
               
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th> -->
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.id')"
                :placeholder="$t('labels.id')"
                name="id"
                sort-name="id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :default-value="filters.id"
              />
            </th>
            <th role="columnheader">
              <SelectEmployee
                :label="$t('labels.employee_create')"
                :placeholder="$t('labels.employee_create')"
                name="id_employee"
                sort-name="employee_name"
                url="/employee/v1/get-by-my-warehouse"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <DateRangeFilter
                :label="$t('labels.created_at')"
                :placeholder="$t('labels.created_at')"
                name="created_at"
                sort-name="created_at"
                :default-date="filters.created_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <DateRangeFilter
                label="Pick"
                placeholder="Pick"
                name="end_picked_at"
                sort-name="end_picked_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="commandTypeOptions"
                :label="$t('labels.type')"
                :placeholder="$t('labels.type')"
                name="command_type"
                sort-name="command_type"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectDeliveryCompany
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                name="id_delivery_company"
                sort-name="delivery_company_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilterFromTo
                :label="$t('labels.total_order')"
                :placeholder="$t('labels.total_order')"
                name="total_order"
                sort-name="total_order"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilterFromTo
                :label="$t('labels.sku_1')"
                :placeholder="$t('labels.sku_1')"
                name="total_sku"
                sort-name="total_sku"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilterFromTo
                :label="$t('labels.total_goods')"
                :placeholder="$t('labels.total_goods')"
                name="total_item"
                sort-name="total_item"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.stop_pickup')"
                :placeholder="$t('labels.stop_pickup')"
                name="is_cancelled"
                sort-name="is_cancelled"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilterFromTo
                :label="$t('labels.stop_pickup_quantity')"
                :placeholder="$t('labels.stop_pickup_quantity')"
                name="cancel_quantity"
                sort-name="cancel_quantity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.basket_code')"
                :placeholder="$t('labels.basket_code')"
                name="basket_code"
                sort-name="basket_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :default-value="filters.basket_code"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <!-- <td>{{ item.warehouse_code }}</td> -->
            <td>
              <span
                class="cursor-pointer blue--text text-decoration-underline"
                @click="showDialogDetail(item.id)"
              >
                {{ item.id }}
              </span>
            </td>
            <td>{{ item.employee_name }}</td>
            <td>{{ formatDateTime(item.created_at) }}</td>
            <td>
              {{ item.end_picked_at ? formatDateTime(item.end_picked_at) : "" }}
            </td>
            <td>{{ getTextByCommandType(item) }}</td>
            <td>{{ item.delivery_company_name }}</td>
            <td>{{ item.total_order }}</td>
            <td>{{ item.total_sku }}</td>
            <td>{{ item.total_item }}</td>
            <td>{{ item.is_cancelled ? "YES" : "NO" }}</td>
            <td>{{ item.cancel_quantity }}</td>
            <td>
              <PickupCopyBaskets :baskets="item.baskets" />
              <div v-html="renderBasket()"></div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="4">
        <div
          class="d-flex align-center table-footer-count"
          style="height: 40px"
        >
          <div class="mr-2">
            {{ $t("labels.command_1") }}: <b>{{ formatNumber(sum.command) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.picked") }}:
            <b>{{ formatNumber(sum.commandPicked) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.total_order") }}: <b>{{ formatNumber(sum.order) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.total_goods") }}: <b>{{ formatNumber(sum.item) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.stop") }}: <b>{{ formatNumber(sum.sumCancel) }}</b>
          </div>
          <v-menu
            v-if="countByType && countByType.length > 0"
            bottom
            origin="center center"
            transition="scale-transition"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on"
                >mdi-dots-vertical</v-icon
              >
            </template>

            <v-list min-width="550px">
              <table style="font-size: 12px; width: 100%">
                <tbody>
                  <tr
                    v-for="(item, i) in countByType"
                    :key="`cbt_${i}`"
                    class="text-right"
                  >
                    <td class="py-1 px-2">
                      {{ getTextByCommandType(item) }}
                    </td>
                    <td class="py-1 px-2">
                      <b class="blue--text">
                        {{ formatNumber(item.count_type) }}
                      </b>
                      {{ $t("labels.command") }}
                    </td>
                    <td class="py-1 px-2">
                      <b class="blue--text">
                        {{ formatNumber(item.sum_order) }}
                      </b>
                      {{ $t("labels.total_order") }}
                    </td>
                    <td class="py-1 px-2">
                      <b class="blue--text">
                        {{ formatNumber(item.sum_item) }}</b
                      >
                      {{ $t("labels.total_goods") }}
                    </td>
                    <td class="py-1 px-2">
                      <b class="blue--text">
                        {{
                          formatNumber((item.sum_order / sum.order) * 100, 2)
                        }}
                      </b>
                      %
                      {{ $t("labels.total_order") }}
                    </td>
                    <td class="py-1 px-2">
                      <b class="blue--text">
                        {{ formatNumber((item.sum_item / sum.item) * 100, 2) }}
                      </b>
                      %
                      {{ $t("labels.total_goods") }}
                    </td>
                  </tr>
                  <tr class="text-right">
                    <td class="py-1 px-2">
                      {{ $t("labels.sum") }}
                    </td>
                    <td class="py-1 px-2">
                      <b class="blue--text">{{ formatNumber(sum.command) }}</b>
                      {{ $t("labels.command") }}
                    </td>
                    <td class="py-1 px-2">
                      <b class="blue--text">{{ formatNumber(sum.order) }}</b>
                      {{ $t("labels.total_order") }}
                    </td>
                    <td class="py-1 px-2">
                      <b class="blue--text">{{ formatNumber(sum.item) }}</b>
                      {{ $t("labels.total_goods") }}
                    </td>
                    <td class="py-1 px-2"></td>
                    <td class="py-1 px-2"></td>
                  </tr>
                </tbody>
              </table>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" sm="4" class="text-right">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="2"></v-col>
      <v-col cols="2">
        <v-text-field
          dense
          outlined
          hide-details
          v-model.number="page"
          :label="$t('labels.page')"
          :placeholder="$t('labels.page')"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-dialog v-model="detailDialog" persistent max-width="1024px">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="hideDialogDetail">
            {{ $t("labels.close") }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-simple-table
              fixed-header
              class="table-padding-2"
              style="max-height: 75vh; overflow-y: auto"
            >
              <template v-slot:default>
                <thead class="v-data-table-header">
                  <tr>
                    <th class="text-center">{{ $t("labels.tracking") }}</th>
                    <th class="text-center">
                      {{ $t("labels.warehouse_state") }}
                    </th>
                    <th class="text-center">{{ $t("labels.basket_state") }}</th>
                    <th class="text-center">{{ $t("labels.basket") }}</th>
                    <th class="text-center">{{ $t("labels.sku") }}</th>
                    <th class="text-center">{{ $t("labels.barcode") }}</th>
                    <th class="text-center">{{ $t("labels.position") }}</th>
                    <th class="text-center">{{ $t("labels.quantity") }}</th>
                    <th class="text-center">{{ $t("labels.picked") }}</th>
                    <th class="text-center">{{ $t("labels.status") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in detailItems"
                    :key="item.id"
                    class="text-center"
                  >
                    <td>
                      <OrderTracking :tracking-id="item.tracking_id" />
                    </td>
                    <td>{{ $t(`labels.order_status_${item.state}`) }}</td>
                    <td>{{ item.basket_index }}</td>
                    <td>{{ item.basket_code }}</td>
                    <td>{{ item.goods_sku }}</td>
                    <td>{{ item.goods_barcode }}</td>
                    <td>
                      <div v-html="renderPickupCells(item.pickup_cells)"></div>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.picked }}</td>
                    <td>{{ item.status ? "YES" : "NO" }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  debounce,
  formatDateTime,
  downloadExcelFile,
  formatNumber,
  getUrlParameter,
} from "@/libs/helpers";
import {
  PICKUP_COMMAND_PROCESS_TYPE,
  PICKUP_COMMAND_TYPE,
  PICKUP_COMMAND_TYPE_2,
  PICKUP_COMMAND_STATUS,
  YES_NO_OPTIONS,
} from "@/libs/const";
import { httpClient } from "@/libs/http";
import moment from "moment/moment";

export default {
  components: {
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    // SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectDeliveryCompany: () =>
      import("@/components/table/SelectDeliveryCompany"),
    OrderTracking: () => import("@/components/common/OrderTracking"),
    PickupCopyBaskets: () =>
      import("@/components/goods_issue/PickupCopyBaskets"),
  },
  name: "PickupHistory",
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    filters: {},
    statusOptions: [...PICKUP_COMMAND_STATUS],
    processTypeOptions: [...PICKUP_COMMAND_PROCESS_TYPE],
    commandTypeOptions: [...PICKUP_COMMAND_TYPE],
    yesNoOptions: [...YES_NO_OPTIONS],
    isLoading: false,
    detailDialog: false,
    detailItems: [],
    countByType: [],
    sum: {},
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    const id = this.getUrlParameter("id");
    if (id) {
      this.filters = { ...this.filters, id };
    } else {
      this.filters = {
        ...this.filters,
        created_at: [
          moment().format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      };
    }
    const basket_code = this.getUrlParameter("basket_code");
    if (basket_code) {
      this.filters = { ...this.filters, basket_code };
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    formatDateTime,
    downloadExcelFile,
    formatNumber,
    getUrlParameter,
    getList: debounce(function () {
      httpClient
        .post("/goods-issue-detail/v1/pickup-list-command", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
          this.countByType = [...data.countByType];
          this.sum = {
            command: data.sumCommand,
            commandPicked: data.sumCommandPicked,
            sumCancel: data.sumCancel,
            order: data.sumOrder,
            item: data.sumItem,
          };
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showDialogDetail(id) {
      this.detailDialog = true;
      httpClient
        .post("/goods-issue-detail/v1/pickup-command-detail", { id })
        .then(({ data }) => {
          this.detailItems = [...data];
        });
    },
    hideDialogDetail() {
      this.detailDialog = false;
      this.detailItems = [];
    },
    getTextByCommandType(item) {
      if (!item.command_type && item.command_type !== 0) {
        return null;
      }

      if (item.type == 2) {
        const pc = [...PICKUP_COMMAND_TYPE_2].find(
          (ct) => ct.value === item.command_type
        );
        return (pc && pc.text) || null;
      }

      const pc = this.commandTypeOptions.find(
        (ct) => ct.value === item.command_type
      );
      return (pc && pc.text) || null;
    },
    renderBasket(baskets) {
      if (!baskets) {
        return null;
      }
      const jBasket = JSON.parse(baskets);
      if (!jBasket || jBasket.length === 0) {
        return null;
      }

      let html = "";
      for (let j = 0; j < jBasket.length; j++) {
        const jb = jBasket[j];
        html += `<div>⊛ ${jb.index} - ${jb.code}</div>`;
      }
      return html;
    },
    renderPickupCells(cells) {
      if (!cells) {
        return "";
      }
      const cellSplit = cells.split(";");
      return cellSplit.join("<br>");
    },
  },
};
</script>

<style scoped></style>
